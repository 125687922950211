import React from "react";
import Wrapper, { WrapperProps } from "./Wrapper";
import Loader, { Loading } from "../Loader";

export const LoadingWrapper = function ({ loaders, children, wrapperProps, className }: { loaders: Loading[], className?: string; children?: any, wrapperProps?: WrapperProps } ) {
    
    const isLoading = loaders.some(a => a.isLoading);

    if (!isLoading) return <Wrapper {...wrapperProps} className={className}>{children}</Wrapper>
    return <Wrapper {...wrapperProps} className={className}>
        <Loader loaders={loaders} />
    </Wrapper>

}