import React from "react";
import { Spinner } from "react-bootstrap";

export interface Loading {
    isLoading: boolean,
    text?: string   
}

export default function Loader({loaders, children} : {loaders?:Loading[] | boolean, children?: any}) {

    const loaderParam = loaders ?? true;
    const isBool = typeof loaderParam === "boolean";
    const isLoading = isBool ? loaderParam : (loaderParam as Loading[]).some(a => a.isLoading);
    const loadersObj = isBool ? [{ isLoading: loaderParam }] as Loading[] : (loaderParam as Loading[]);

    if (!isLoading) return <>{children}</>
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <div>
          {loadersObj.map((a, index) => (
            <div
              key={index}
              style={{
                fontSize: "120%",
                minHeight: "40px",
                animationDelay: `${index * 150}ms`,
              }}
              className="animated fadeInUp d-flex align-items-center"
            >
              <span className="flex-grow-1 mr-2 text-secondary-foreground">
                {a.text ?? "Loading..."}
              </span>{" "}
              <span style={{ minWidth: "75px" }}>
                {a.isLoading ? (
                  <Spinner variant="dark" animation="grow" />
                ) : (
                  <span className="mb-0 ml-3 alert alert-success py-1 px-2 d-inline-block animated fadeInUp faster">
                    Done
                  </span>
                )}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
}