// ModalMessage.tsx
import React from "react";
import { Modal } from "react-bootstrap";

interface ModalMessageProps {
  showModal: boolean;
  handleClose: () => void;
}

const ModalMessage: React.FC<ModalMessageProps> = ({
  showModal,
  handleClose,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="custom-backdrop-modal"
    >
      <Modal.Body>
        <h3 className="text-center">Please use this application on desktop.</h3>
        <h4 className="text-center">Mobile version coming soon!</h4>
      </Modal.Body>
    </Modal>
  );
};

export default ModalMessage;
