import React, { useState } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import useRootStore from "../../hooks/rootStoreHook";
import Dropzone from "../Tools/Dropzone/DropzoneImages";

const CreateFeedback = ({ showModal, closeModal }) => {
    const [feedbackComment, setFeedbackComment] = useState("");
    const [referencePage, setReferencePage] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState<PreviewFile[]>([]);
    const [errors, setErrors] = useState({ feedbackComment: "", referencePage: "" });
    const [submitStatus, setSubmitStatus] = useState({ type: "", message: "" });
    const [loading, setLoading] = useState(false);
    const { apiClient } = useRootStore();

    interface PreviewFile extends File {
        preview: string;
    }

    const handleCurrentPage = () => {
        setReferencePage(window.location.href);
        setErrors((prevErrors) => ({ ...prevErrors, referencePage: "" }));
    };

    const handleFilesChange = (files: any) => {
        setUploadedFiles(files);
    };

    const handleFeedbackChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFeedbackComment(e.target.value);
        if (e.target.value.trim()) {
            setErrors((prevErrors) => ({ ...prevErrors, feedbackComment: "" }));
        }
    };

    const handleReferencePageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReferencePage(e.target.value);
        if (e.target.value.trim()) {
            setErrors((prevErrors) => ({ ...prevErrors, referencePage: "" }));
        }
    };

    const validateFields = () => {
        let valid = true;
        let errors = { feedbackComment: "", referencePage: "" };

        if (!feedbackComment.trim()) {
            errors.feedbackComment = "Feedback comment is required.";
            valid = false;
        }

        if (!referencePage.trim()) {
            errors.referencePage = "Reference page URL is required.";
            valid = false;
        }

        setErrors(errors);
        return valid;
    };

    const handleSubmit = async () => {
        if (!validateFields()) {
            return;
        }

        setLoading(true);

        try {
            const formData = new FormData();
            formData.append('FeedbackComment', feedbackComment);
            formData.append('ReferencePage', referencePage);
            uploadedFiles.forEach((image, index) => {
                formData.append(`Images`, image);
            });

            const response = await apiClient.post("/Feedback/CreateFeedback", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                setSubmitStatus({ type: "success", message: "Thanks for your Feedback!" });
                setFeedbackComment('');
                setReferencePage('');
                setUploadedFiles([]);
                setTimeout(() => {
                    setSubmitStatus({ type: "", message: "" });
                    closeModal();
                }, 1200);
            }
        } catch (error) {
            console.error("Error submitting feedback:", error);
            setSubmitStatus({ type: "error", message: "Sorry, failed to submit feedback." });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            show={showModal}
            onHide={loading ? undefined : closeModal}
            backdrop={loading ? 'static' : true}
            keyboard={!loading}
        >
            {loading && (
                <div className="overlay">
                    <div className="spinner"></div>
                </div>
            )}
            {submitStatus.message && !loading && (
                <div className={`overlay ${submitStatus.type}`}>
                    <Alert className="bootAlertStyle">
                        <span className={submitStatus.type === "success" ? "custom-alert-success" : "custom-alert-error"}>
                            {submitStatus.message}
                        </span>
                    </Alert>
                </div>
            )}
            <Modal.Header>
                <Modal.Title>Submit Feedback</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="feedbackComment">
                        <Form.Label>Feedback</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={feedbackComment}
                            onChange={handleFeedbackChange}
                            isInvalid={!!errors.feedbackComment}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.feedbackComment}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="referencePage" className="referencePageInput">
                        <Form.Label>Reference Page</Form.Label>
                        <div className="d-flex">
                            <Form.Control
                                type="text"
                                value={referencePage}
                                onChange={handleReferencePageChange}
                                placeholder="Enter reference page URL"
                                className="reference"
                                isInvalid={!!errors.referencePage}
                            />
                            <Button variant="primary" onClick={handleCurrentPage} className="ml-2">
                                Current Page
                            </Button>
                        </div>
                        {errors.referencePage && (
                            <Form.Text className="text-danger">
                                {errors.referencePage}
                            </Form.Text>
                        )}
                    </Form.Group>
                </Form>
                <Dropzone className="custom-dropzone" onFilesChange={handleFilesChange} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateFeedback;
