
export type Variant = "primary" | "secondary" | "outline" ;

const customSelect = (variant?: Variant) => {
  const finalVariant = variant ?? "secondary";

  return {
    control: (provided, state) => ({
      ...provided,
      backgroundColor:
        finalVariant === "secondary"
          ? "var(--secondary)"
          : variant === "primary"
          ? "var(--input)"
          : "transparent",
      borderColor: state.isFocused ? "var(--border)" : "var(--border)",
      borderRadius: "8px",
      color: "var(--secondary-foreground)",
      cursor: "pointer",
      padding: "3px",
      "&:hover": {
        borderColor: state.isFocused ? "var(--border)" : "var(--border)",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#F9F5FF" : "var(--input)",
      color: state.isSelected
        ? "var(--foreground)"
        : "var(--secondary-foreground)",
      cursor: "pointer",
      "&:hover": {
        color: "var(--foreground)",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      borderRadius: "8px",
      border: "1px solid var(--border)",
      overflow: "hidden",
      backgroundColor:
        finalVariant === "secondary"
          ? "var(--secondary)"
          : variant === "primary"
          ? "var(--input)"
          : "var(--input)",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "var(--secondary-foreground)",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "var(--secondary-foreground)",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      borderRadius: "8px",
      border: "1px solid var(--border)",
      color: "var(--foreground)",
      fontSize: "16px",
    }),
  };

};

export const customAsyncSelect = (
  variant: "primary" | "secondary" | "outline" = "secondary"
): Record<string, (provided: any, state: any) => any> => ({
  control: (provided, state) => ({
    ...provided,
    backgroundColor:
      variant === "secondary"
        ? "var(--secondary)"
        : variant === "primary"
        ? "var(--input)"
        : "transparent",
    borderColor: state.isFocused ? "var(--border)" : "var(--border)",
    borderRadius: "8px",
    color: "var(--secondary-foreground)",
    cursor: "pointer",
    padding: "3px",
    "&:hover": {
      borderColor: state.isFocused ? "var(--border)" : "var(--border)",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#F9F5FF" : "var(--input)",
    color: state.isSelected
      ? "var(--foreground)"
      : "var(--secondary-foreground)",
    cursor: "pointer",
    "&:hover": {
      color: "var(--foreground)",
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    borderRadius: "8px",
    border: "1px solid var(--border)",
    backgroundColor:
      variant === "secondary"
        ? "var(--secondary)"
        : variant === "primary"
        ? "var(--input)"
        : "var(--input)",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "var(--secondary-foreground)",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "var(--secondary-foreground)",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
    borderRadius: "8px",
    border: "1px solid var(--border)",
    color: "var(--foreground)",
    fontSize: "16px",
  }),
});

export default customSelect;
