import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";

const ErrorInterface = () => {
  const navigate = useNavigate();

  return (
    <section
      className="section-container d-flex align-items-center justify-content-center text-center"
      style={{ minHeight: "calc(100vh - 72px)" }}
    >
      <div className="content-wrapper h-100">
        <div
          className="bg-card border-main p-4 rounded-xl mx-auto"
          style={{ width: "50%" }}
        >
          <h1 className="text-foreground mb-4">
            An unexpected error occurred. Please refresh the page or try again
            later.
          </h1>
          <p className="text-secondary-foreground text-lg mb-4">
            If the issue persists, please contact support.
          </p>
          <Button
            onClick={() => {
              navigate("/");
              window.location.reload();
            }}
          >
            Go to Home Page
          </Button>
        </div>
      </div>
    </section>
  );
};

export default ErrorInterface;
