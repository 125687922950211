import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip, Modal, Tabs, Tab, Dropdown, Form, InputGroup, Button, Alert } from "react-bootstrap";
import { Space } from "../../models/Spaces";
import { toast } from "../Helper_component/Swal";
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import { useForm, } from "react-hook-form";
import { Unwrap } from "../../utils/api/apiClient";
import AuthService from "../../utils/AuthService"
import useRootStore from "../../hooks/rootStoreHook";
import { observer } from "mobx-react-lite";

const SpaceSelector: React.FC = observer((props) => {

    const { apiClient, sessionStore } = useRootStore();

    const [showModal, setShowModal] = useState(false);
    const { register, handleSubmit, errors, reset } = useForm<{ name: string, subDomain: string }>();
    const currentSpace = sessionStore.currentSpace;
    const hasSpaces = sessionStore.hasSpaces;
    const loadingLicense = sessionStore.loading;

    async function addSpace(data: { name: string, subDomain: string }) {
        toast.fire({
            title: "Creating space...",
            icon: undefined,
            timer: undefined
        });
        try {
            let res = Unwrap.response(await apiClient.post("spaces", data));
            if (res.error) throw res.messages;
            sessionStore.addUserSpace(res.result);
            reset();
            toast.fire("Space created!");
        }
        catch (e) {
            console.error(e);
        }
    }

    //If not in a space but has spaces, show the selection modal. We use a timeout to ensure everything has loaded properly before viewing the modal
    useEffect(() => {
        if (loadingLicense) return;
        if (hasSpaces && !currentSpace) {
            setShowModal(true);
        }
    }, [hasSpaces, currentSpace, loadingLicense]);

    async function goToSpace(space: Space, newWindow = true) {
        let param = await AuthService.createUserTransfer();
        const url = `${window.location.protocol}//${space.domain}${window.location.pathname}?${param}`;
        if (newWindow) window.open(url);
        else window.location.href = url;
    }

    function setSpace(space: Space) {
        if (!currentSpace) { //We need to be in a space for license to work, otherwise it gets all crazy. So if we're not in a space, we need to change domain completely.
            goToSpace(space, false);
            return;
        }
        sessionStore.setCurrentSpace(space);
        setShowModal(false);
        toast.fire(`Set workspace to ${space.name}`);
    }

    function renderSpaces(noText: string, spaces: Space[] | undefined) {
        return !spaces?.length ? <div className="text-secondary-foreground text-center">{noText}</div> : <>
            {!currentSpace ? <div className="d-flex justify-content-center">
                <Alert variant="primary">Currently not in a workspace, select which one to access</Alert>
            </div> : null}
            <table className="table table-striped">
                <thead>
                    <th>Name</th>
                    <th>Domain</th>
                    <th>&nbsp;</th>
                </thead>
                <tbody>
                    {renderSpacesRows(spaces)}
                </tbody>
            </table></>
    }

    function renderSpacesRows(spaces: Space[] | undefined) {
        if (!spaces) return null;

        return <>
            {spaces.map(space => {
                let isCurrent = currentSpace?.id === space.id;
                return <tr className={isCurrent ? 'table-primary' : ""} key={space.id}>
                    <td className="align-middle text-nowrap">{space.name}</td>
                    <td className="align-middle">{space.domain}</td>
                    <td className="text-right align-middle">
                        <Dropdown>
                            <Dropdown.Toggle disabled={isCurrent} variant="secondary" id="dropdown-basic">
                                {isCurrent ? "Current" : "Use"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => { setSpace(space) }}>Set as workspace</Dropdown.Item>
                                <Dropdown.Item onClick={() => goToSpace(space)}>Open in new window</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            })}</>;
    }

    function renderAddSpace() {
        if (!currentSpace) return null;
        return <form onSubmit={handleSubmit(addSpace)}><div className="font-weight-bold text-secondary-foreground">
            Add a new space
        </div><table className="table">
                <tr>
                    <td>
                        <Form.Control placeholder="Workspace name" ref={register({ required: true }) as any} name="name" isInvalid={!!errors.name} />
                        <Form.Control.Feedback type="invalid">A name is required</Form.Control.Feedback>
                    </td>
                    <td>
                        <InputGroup>
                            <Form.Control placeholder="Subdomain" ref={register({ required: 'Required', minLength: { value: 5, message: "Min five characters" }, maxLength: { value: 20, message: "Max 20 characters" } }) as any} name="subDomain" isInvalid={!!errors.subDomain} />

                            <InputGroup.Text>
                                .{process.env.REACT_APP_DOMAIN}
                            </InputGroup.Text>

                            <Form.Control.Feedback type="invalid">{errors.subDomain?.message}</Form.Control.Feedback>
                        </InputGroup>

                    </td>
                    <td className="text-right">
                        <Button type="submit" disabled={!!errors.name || !!errors.subDomain}>Add</Button>
                    </td>
                </tr>
            </table>
        </form>
    }

    function renderModalBody() {
        return <Tabs className="mb-4" defaultActiveKey="userSpaces" id="space-selector-modal-tabs">
            <Tab eventKey="userSpaces" title="Your spaces">
                {renderSpaces("You have no user spaces", sessionStore.spaces?.userSpaces)}
                {renderAddSpace()}
            </Tab>
            <Tab eventKey="collaborationSpaces" title="Collaboration spaces">
                {renderSpaces("You have no collaboration spaces", sessionStore.spaces?.collaborationSpaces)}
            </Tab>
        </Tabs>
    }

    if (!hasSpaces) return null;
    return <>
        <OverlayTrigger placement="right" overlay={<Tooltip id="working-space-tooltip">Your current workspace</Tooltip>}>
            <div>
                <a onClick={(e) => { setShowModal(true); e.preventDefault(); }} href="#">{!!currentSpace ? <>{currentSpace.name}</> : "Not in a workspace"}</a>
                {/* { hasSpaces ? <button className="btn btn-xs mt-1 btn-oval btn-secondary" >Change</button> : null } */}
            </div>
        </OverlayTrigger>

        {/* {authContext?.state.user ?
            <OverlayTrigger placement="right" overlay={<Tooltip id="user-tooltip">{authContext?.state.user.profile.email}</Tooltip>}>
                <div className="bg-green text-center p-2">
                    <span className="d-flex align-items-center justify-content-center text-white">
                        <InfoIcon/>
                        { authContext?.state.user.profile.given_name + " " + authContext?.state.user.profile.family_name}
                    </span>
                </div>
            </OverlayTrigger> : null
            } */}

        {/* {authContext?.state.user ?
                <div className="bg-green text-center p-2">
                    <span className="d-flex align-items-center justify-content-center text-white">
                        <InfoIcon font-size="small"/>
                        { authContext?.state.user.profile.given_name + " " + authContext?.state.user.profile.family_name}
                    </span>
                    <small className="d-flex text-muted text-white">
                        {authContext?.state.user.profile.email}
                    </small>
                </div>: null } */}
        <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
            <Modal.Body>
                {renderModalBody()}
            </Modal.Body>
        </Modal>
    </>
});
export default SpaceSelector;