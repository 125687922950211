import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import { ArrowDownIcon, ArrowUpIcon } from "../../assets/icons";

interface SidebarItemProps {
  icon: any;
  path?: string; // Made path optional
  name: string;
  menu?: any;
  isExpanded?: boolean;
  onClick?: () => void;
}

const SidebarItem: FC<SidebarItemProps> = ({
  icon,
  path = "#",
  name,
  menu,
  isExpanded,
  onClick,
}) => {
  // const [expand, setExpand] = useState(false);
  const location = useLocation();

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (menu && menu.length) {
      event.preventDefault();
      // setExpand((prev) => !prev);
      if (onClick) onClick(); 
    }
  };

  const isActive = (): boolean => {
    return location.pathname === path;
  };

  return (
    <li>
      <Link
        onClick={handleClick}
        to={menu && menu.length ? "#" : path}
        title={name}
        style={{ whiteSpace: "normal" }}
        className={`d-flex align-items-center font-bold ${
          isActive() ? "active" : ""
        }`}
      >
        {icon}
        <span className="ml-3">{name}</span>
        {menu && menu.length ? (
          <div className="ml-auto">
            {isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </div>
        ) : null}
      </Link>
      {menu && menu.length ? (
        <ul
          className={`text-secondary-foreground submenu ${
            isExpanded ? "expand" : ""
          }`}
          style={{ paddingLeft: 66 }}
        >
          {menu.map((item: { path: string; name: string }, index: number) => (
            <NavLink
              key={index}
              to={item.path}
              title={item.name}
              style={{ whiteSpace: "normal" }}
              className={
                "d-flex align-items-center font-medium text-sidebar-foreground my-2"
              }
            >
              {item.name}
            </NavLink>
          ))}
        </ul>
      ) : null}
    </li>
  );
};

export default SidebarItem;
