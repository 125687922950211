import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { registerLicense } from "@syncfusion/ej2-base";
// registerLicense(
//   "NTc5OTQyQDMxMzkyZTM0MmUzMG9yMHNTbk52akdKUWhvYkN5SlJkOWdzZElTeWhVSGgxZzZ3UlV4R21rMU09"
// );

registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF1cWmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjUH1bcXJVQGNcUkxwXg=="
);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
